body {
    margin: 0;
}

/* MUI DateTimePicker Overrides */

.MuiPickersModal-dialogRoot {
    transform: scale(1.05);
}

.MuiPickersModal-dialogRoot {
    padding: 5px !important;
    border-radius: 8px !important;
}

.MuiPickersModal-dialogRoot .MuiTabs-indicator {
    background-color: #ffffff80 !important;
}

.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar .MuiButton-root h4 {
    font-size: 3em !important;
}

.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar .MuiButton-root h4 {
    font-size: 3em !important;
}

.MuiPickersModal-dialogRoot .MuiPickersToolbar-toolbar .MuiButton-root h3 {
    font-size: 3em !important;
}

.MuiPickersModal-withAdditionalAction .MuiButton-label {
    font-size: 1.2em !important;
}

.MuiPickersModal-withAdditionalAction .MuiButton-textPrimary:hover {
    text-decoration: none !important;
}

.MuiPickersModal-dialogRoot .MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1 {
    font-size: 1em !important;
}
